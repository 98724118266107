<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-2">
        <h5 v-if="attendance>0">Attendance : {{attendance}}</h5>
        <h5 v-else>Attendance : N/A</h5>
      </div>
      <div class="col-md-2">
        <h5>Referee: {{ refs[0].referee.first_name }} {{ refs[0].referee.last_name }}</h5>
      </div>
      <div class="col-md-2">
        <h5>Ground : {{ground.name}}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FootballRefs',
  props: {
    refs: {
      type: Array,
      required: true,
    },
    attendance: {
      required: true,
    },
    ground: {
      required: true,
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 3em;
  margin-bottom: 1em;
}
</style>

<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl">
        <h5>{{home_team_formation_label}}</h5>
      </div>
      <div class="col-xl">
        <h5>{{kickoff_time}}</h5>
      </div>
      <div class="col-xl">
        <h5>{{away_team_formation_label}}</h5>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'FootballFormation',
  props: {
    home_team_formation_label: {
      type: String,
      default: 'Home Team Formation',
    },
    away_team_formation_label: {
      type: String,
      default: 'Away Team Formation',
    },
    kickoff_time: {
      type: String,
      default: 'Kickoff Time',
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 1em;
}
</style>

<template>
  <ul class="list-group list-group-flush">
    <li class="list-group-item" v-for="player in players" :key="player">
      <div class="container">
        <div class="row">
          <div class="col-1">
            <div class="h8">{{player.match_shirt_number}}</div>
          </div>
          <div class="col" v-if="player.last_name != null">
            <div class="h8">{{player.last_name}}</div>
          </div>
          <div class="col" v-if="player.last_name == null && player.first_name != null">
            <div class="h8">{{player.first_name}}</div>
          </div>
          <div class="col" v-if="player.last_name == null && player.first_name == null">
            <div class="h8">N/A</div>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <ul class="list-group list-group-flush">
    <div class="container" style="padding: 0.5em;">
      <div class="col-all">Substitutes</div>
    </div>
    <li class="list-group-item" v-for="sub in substitutes" :key="sub">
      <div class="container">
        <div class="row">
          <div class="col-1">
            <div class="h8">{{sub.match_shirt_number}}</div>
          </div>
          <div class="col" v-if="sub.last_name != null">
            <div class="h8">{{sub.last_name}}</div>
          </div>
          <div class="col" v-if="sub.last_name == null && sub.first_name != null">
            <div class="h8">{{sub.first_name}}</div>
          </div>
          <div class="col" v-if="sub.last_name == null && sub.first_name == null">
            <div class="h8">N/A</div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'FootballLineup',
  props: {
    players: {
      type: Array,
      required: true,
    },
    substitutes: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.list-group {
  width: 100%;
  font-size: 1.2em;
}
.list-group-item {
  background-color: #222222;
  padding: 0;
}
</style>

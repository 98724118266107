<template>
  <div class="card" id="timeline-card">
      <Timeline :value="events" layout="vertical" align="alternate" :key="event">
        <template #marker="slotProps">
          <span class="custom-marker shadow-2">
            <i :class="slotProps.item.icon">{{ slotProps.item.time }}</i>
          </span>
        </template>
      <template #content="slotProps">
        <div class="card card-success" v-if="slotProps.item.type === 'G'">
          <div class="card card-primary">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <img src="https://img.icons8.com/external-justicon-lineal-color-justicon/32/000000/external-football-sport-justicon-lineal-color-justicon.png"/>                </div>
                <div class="col-auto">
                  <p class="card-text">{{slotProps.item.last_name}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-success" v-if="slotProps.item.type === 'S'">
          <div class="card card-secondary">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2">
                  <img v-if="slotProps.item.description == 'OFF'" src="https://img.icons8.com/ios-filled/25/fa314a/arrow.png"/>
                  <img v-if="slotProps.item.description == 'ON'" src="https://img.icons8.com/ios-filled/25/26e07f/arrow.png"/>
                </div>
                <div class="col col-auto">
                  <p class="card-text">{{ slotProps.item.first_name }}
                    {{ slotProps.item.last_name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-success" v-if="slotProps.item.description === 'Y'">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2">
                  <img src="https://img.icons8.com/external-konkapp-outline-color-konkapp/32/000000/external-yellow-card-soccer-konkapp-outline-color-konkapp.png" alt=""/>
                </div>
                <div class="col">
                  <p class="card-text">{{slotProps.item.last_name}}</p>
                </div>
              </div>
            </div>
          </div>
        <div class="card card-success" v-if="slotProps.item.description === 'R'">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2">
                <img src="https://img.icons8.com/external-icongeek26-linear-colour-icongeek26/32/000000/external-red-card-football-icongeek26-linear-colour-icongeek26.png"/>              </div>
              <div class="col">
                <p class="card-text">{{slotProps.item.last_name}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-success" v-if="slotProps.item.description === 'YR'">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2">
                <img src="https://img.icons8.com/external-icongeek26-linear-colour-icongeek26/32/000000/external-red-card-football-icongeek26-linear-colour-icongeek26.png"/>              </div>
              <div class="col">
                <p class="card-text">{{slotProps.item.last_name}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-success" v-if="slotProps.item.type === 'PE'">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/32/000000/external-stopwatch-business-and-teamwork-flatart-icons-outline-flatarticons.png"/>
              </div>
              <div class="col col-auto">
                <p class="card-text" v-if="slotProps.item.time==='45\'00'">Half Time!</p>
                <p class="card-text" v-if="slotProps.item.time.includes('45 +')">Half Time!</p>
                <p class="card-text" v-if="slotProps.item.time==='90\'00'">Full Time!</p>
                <p class="card-text" v-if="slotProps.item.time.includes('90 +')">Full Time!</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Timeline>
  </div>
</template>

<script>
import Timeline from 'primevue/timeline';

export default {
  name: 'FootballTimeline',
  components: {
    Timeline,
  },
  props: ['events'],
};
</script>

<style scoped>
#timeline-card {
  border: 0;
  margin: 1rem;
  background-color: #222222;
}
.card {
  /*width: 1em;*/
  border-radius: 1em;
}
.card-header {
  background-color: #222222;
  color: lightgray;
  border-radius: 1em 1em 0 0;
  height: 2.5em;
}
.card-body {
  color: lightgray;
}
.card:hover {
  background-color: #222222;
  color: lightgray;
}
</style>

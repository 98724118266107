<template>
  <div>
    <h2>Threads</h2>
    <ul class="list-group list-group-flush">
      <li class="list-group-item" v-for="thread in threads" :key="thread">
        <div class="row">
          <div class="col"
               v-on:click="this.$router.push('/matchThread/'+matchId+'/'+thread.match_thread_id)">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">{{thread.thread_name}}</h5>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Threads',
  props: ['matchId', 'threads'],
  methods: {
  },
};
</script>

<style scoped>
.card{
  border-right-color: #303030;
  border-left-color: #303030;
}
</style>

<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col" id="home-team-badge">
        <img class="img-fluid" :src="homeTeamBadge" alt="">
      </div>
      <div class="col-xl">
        <div class="h2"> {{homeTeam}} </div>
      </div>
      <div class="col">
        <div class="h2"> {{homeScore}} : {{awayScore}} </div>
      </div>
      <div class="col-xl">
        <div class="h2"> {{awayTeam}} </div>
      </div>
      <div class="col" id="away-team-badge">
        <img class="img-fluid" :src="awayTeamBadge" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FootballMatch',
  props: {
    homeTeam: {
      type: String,
      required: true,
    },
    awayTeam: {
      type: String,
      required: true,
    },
    homeScore: {
      type: Number,
      required: true,
    },
    awayScore: {
      type: Number,
      required: true,
    },
    homeTeamId: {
      type: Number,
      required: true,
    },
    awayTeamId: {
      type: Number,
      required: true,
    },
    homeTeamBadge: {
      type: String,
      required: true,
    },
    awayTeamBadge: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.img-fluid {
  max-width: 100%;
  height: auto;
}
.container {
  margin-top: 1em;
}
</style>

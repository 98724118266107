<template>
  <!-- This displays the pitch with releavent formation -->
  <div class="wrapper">
    <div class="Pitch">
      <div class="LeftPitch"></div>
      <div class="RightPitch"></div>
      <div class="Outline"></div>
      <div class="Interior"></div>
      <div class="Penalty"></div>
      <div class="LEFT-4-4-2" v-if="home_formation==='4-4-2'">
        <div class="gk">{{ home_players[0].match_shirt_number }}</div>
        <div class="left_lb_4_4_2">{{ home_players[1].match_shirt_number }}</div>
        <div class="left_cb1_4_4_2">{{ home_players[2].match_shirt_number }}</div>
        <div class="left_cb2_4_4_2">{{ home_players[3].match_shirt_number }}</div>
        <div class="left_rb_4_4_2">{{ home_players[4].match_shirt_number }}</div>
        <div class="left_lm_4_4_2">{{ home_players[5].match_shirt_number }}</div>
        <div class="left_cm1_4_4_2">{{ home_players[6].match_shirt_number }}</div>
        <div class="left_cm2_4_4_2">{{ home_players[7].match_shirt_number }}</div>
        <div class="left_rm_4_4_2">{{ home_players[8].match_shirt_number }}</div>
        <div class="left_fw1_4_4_2">{{ home_players[9].match_shirt_number }}</div>
        <div class="left_fw2_4_4_2">{{ home_players[10].match_shirt_number }}</div>
      </div>
      <div class="RIGHT-4-4-2" v-if="away_formation==='4-4-2'">
        <div class="gk2">{{away_players[0].match_shirt_number}}</div>
        <div class="right_lb_4_4_2">{{away_players[1].match_shirt_number}}</div>
        <div class="right_cb1_4_4_2">{{away_players[2].match_shirt_number}}</div>
        <div class="right_cb2_4_4_2">{{away_players[3].match_shirt_number}}</div>
        <div class="right_rb_4_4_2">{{away_players[4].match_shirt_number}}</div>
        <div class="right_lm_4_4_2">{{away_players[5].match_shirt_number}}</div>
        <div class="right_cm1_4_4_2">{{away_players[6].match_shirt_number}}</div>
        <div class="right_cm2_4_4_2">{{away_players[7].match_shirt_number}}</div>
        <div class="right_rm_4_4_2">{{away_players[8].match_shirt_number}}</div>
        <div class="right_fw1_4_4_2">{{away_players[9].match_shirt_number}}</div>
        <div class="right_fw2_4_4_2">{{away_players[10].match_shirt_number}}</div>
      </div>
      <div class="LEFT-4-3-3" v-if="home_formation==='4-3-3'">
        <div class="gk">{{ home_players[0].match_shirt_number }}</div>
        <div class="left_lb_4_4_2">{{ home_players[1].match_shirt_number }}</div>
        <div class="left_cb1_4_4_2">{{ home_players[2].match_shirt_number }}</div>
        <div class="left_cb2_4_4_2">{{ home_players[3].match_shirt_number }}</div>
        <div class="left_rb_4_4_2">{{ home_players[4].match_shirt_number }}</div>
        <div class="left_cdm_4_3_3">{{ home_players[5].match_shirt_number }}</div>
        <div class="left_lcm_4_3_3">{{ home_players[6].match_shirt_number }}</div>
        <div class="left_rcm_4_3_3">{{ home_players[7].match_shirt_number }}</div>
        <div class="left_lw_4_3_3">{{ home_players[8].match_shirt_number }}</div>
        <div class="left_rw_4_3_3">{{ home_players[9].match_shirt_number }}</div>
        <div class="left_cf_4_3_3">{{ home_players[10].match_shirt_number }}</div>
      </div>
      <div class="RIGHT-4-3-3" v-if="away_formation==='4-3-3'">
        <div class="gk2">{{away_players[0].match_shirt_number}}</div>
        <div class="right_lb_4_4_2">{{away_players[1].match_shirt_number}}</div>
        <div class="right_cb1_4_4_2">{{away_players[2].match_shirt_number}}</div>
        <div class="right_cb2_4_4_2">{{away_players[3].match_shirt_number}}</div>
        <div class="right_rb_4_4_2">{{away_players[4].match_shirt_number}}</div>
        <div class="right_cdm_4_3_3">{{away_players[5].match_shirt_number}}</div>
        <div class="right_lcm_4_3_3">{{away_players[6].match_shirt_number}}</div>
        <div class="right_rcm_4_3_3">{{away_players[7].match_shirt_number}}</div>
        <div class="right_lw_4_3_3">{{away_players[8].match_shirt_number}}</div>
        <div class="right_rw_4_3_3">{{away_players[9].match_shirt_number}}</div>
        <div class="right_cf_4_3_3">{{away_players[10].match_shirt_number}}</div>
      </div>
      <div class="LEFT-4-3-2-1" v-if="home_formation==='4-2-3-1'">
        <div class="gk">{{ home_players[0].match_shirt_number }}</div>
        <div class="left_lb_4_2_3_1">{{ home_players[1].match_shirt_number }}</div>
        <div class="left_cb1_4_2_3_1">{{ home_players[2].match_shirt_number }}</div>
        <div class="left_cb2_4_2_3_1">{{ home_players[3].match_shirt_number }}</div>
        <div class="left_rb_4_2_3_1">{{ home_players[4].match_shirt_number }}</div>
        <div class="left_cdm_4_2_3_1">{{ home_players[5].match_shirt_number }}</div>
        <div class="left_cdm2_4_2_3_1">{{ home_players[6].match_shirt_number }}</div>
        <div class="left_cm_4_2_3_1">{{ home_players[7].match_shirt_number }}</div>
        <div class="left_lm_4_2_3_1">{{ home_players[8].match_shirt_number }}</div>
        <div class="left_rm_4_2_3_1">{{ home_players[9].match_shirt_number }}</div>
        <div class="left_cf_4_2_3_1">{{ home_players[8].match_shirt_number }}</div>
      </div>
      <div class="RIGHT-4-2-3-1" v-if="away_formation==='4-2-3-1'">
        <div class="gk2">{{away_players[0].match_shirt_number}}</div>
        <div class="right_lb_4_2_3_1">{{away_players[1].match_shirt_number}}</div>
        <div class="right_cb1_4_2_3_1">{{away_players[2].match_shirt_number}}</div>
        <div class="right_cb2_4_2_3_1">{{away_players[3].match_shirt_number}}</div>
        <div class="right_rb_4_2_3_1">{{away_players[4].match_shirt_number}}</div>
        <div class="right_cdm_4_2_3_1">{{away_players[5].match_shirt_number}}</div>
        <div class="right_cdm2_4_2_3_1">{{away_players[6].match_shirt_number}}</div>
        <div class="right_cm_4_2_3_1">{{away_players[7].match_shirt_number}}</div>
        <div class="right_lm_4_2_3_1">{{away_players[8].match_shirt_number}}</div>
        <div class="right_rm_4_2_3_1">{{away_players[9].match_shirt_number}}</div>
        <div class="right_cf_4_2_3_1">{{away_players[8].match_shirt_number}}</div>
      </div>
      <div class="LEFT-4-5-1" v-if="home_formation==='4-5-1'">
        <div class="gk">{{ home_players[0].match_shirt_number }}</div>
        <div class="left_lb_4_5_1">{{ home_players[1].match_shirt_number }}</div>
        <div class="left_cb1_4_5_1">{{ home_players[2].match_shirt_number }}</div>
        <div class="left_cb2_4_5_1">{{ home_players[3].match_shirt_number }}</div>
        <div class="left_rb_4_5_1">{{ home_players[4].match_shirt_number }}</div>
        <div class="left_cdm_4_5_1">{{ home_players[5].match_shirt_number }}</div>
        <div class="left_lcm_4_5_1">{{ home_players[6].match_shirt_number }}</div>
        <div class="left_rcm_4_5_1">{{ home_players[7].match_shirt_number }}</div>
        <div class="left_lw_4_5_1">{{ home_players[8].match_shirt_number }}</div>
        <div class="left_rw_4_5_1">{{ home_players[9].match_shirt_number }}</div>
        <div class="left_cf_4_5_1">{{ home_players[10].match_shirt_number }}</div>
      </div>
      <div class="RIGHT-4-5-1" v-if="away_formation==='4-5-1'">
        <div class="gk2">{{away_players[0].match_shirt_number}}</div>
        <div class="right_lb_4_5_1">{{away_players[1].match_shirt_number}}</div>
        <div class="right_cb1_4_5_1">{{away_players[2].match_shirt_number}}</div>
        <div class="right_cb2_4_5_1">{{away_players[3].match_shirt_number}}</div>
        <div class="right_rb_4_5_1">{{away_players[4].match_shirt_number}}</div>
        <div class="right_cdm_4_5_1">{{away_players[5].match_shirt_number}}</div>
        <div class="right_lcm_4_5_1">{{away_players[6].match_shirt_number}}</div>
        <div class="right_rcm_4_5_1">{{away_players[7].match_shirt_number}}</div>
        <div class="right_lw_4_5_1">{{away_players[8].match_shirt_number}}</div>
        <div class="right_rw_4_5_1">{{away_players[9].match_shirt_number}}</div>
        <div class="right_cf_4_5_1">{{away_players[10].match_shirt_number}}</div>
      </div>
      <div class="LEFT-4-3-1-2" v-if="home_formation==='4-3-1-2'">
        <div class="gk">{{ home_players[0].match_shirt_number }}</div>
        <div class="left_lb_4-3-1-2">{{ home_players[1].match_shirt_number }}</div>
        <div class="left_cb1_4-3-1-2">{{ home_players[2].match_shirt_number }}</div>
        <div class="left_cb2_4-3-1-2">{{ home_players[3].match_shirt_number }}</div>
        <div class="left_rb_4-3-1-2">{{ home_players[4].match_shirt_number }}</div>
        <div class="left_cdm_4-3-1-2">{{ home_players[5].match_shirt_number }}</div>
        <div class="left_cdm2_4-3-1-2">{{ home_players[6].match_shirt_number }}</div>
        <div class="left_cm_4-3-1-2">{{ home_players[7].match_shirt_number }}</div>
        <div class="left_lm_4-3-1-2">{{ home_players[8].match_shirt_number }}</div>
        <div class="left_rm_4-3-1-2">{{ home_players[9].match_shirt_number }}</div>
        <div class="left_cf_4-3-1-2">{{ home_players[8].match_shirt_number }}</div>
      </div>
      <div class="RIGHT-4-3-1-2" v-if="away_formation==='4-3-1-2'">
        <div class="gk2">{{away_players[0].match_shirt_number}}</div>
        <div class="right_lb_4-3-1-2">{{away_players[1].match_shirt_number}}</div>
        <div class="right_cb1_4-3-1-2">{{away_players[2].match_shirt_number}}</div>
        <div class="right_cb2_4-3-1-2">{{away_players[3].match_shirt_number}}</div>
        <div class="right_rb_4-3-1-2">{{away_players[4].match_shirt_number}}</div>
        <div class="right_cdm_4-3-1-2">{{away_players[5].match_shirt_number}}</div>
        <div class="right_cdm2_4-3-1-2">{{away_players[6].match_shirt_number}}</div>
        <div class="right_cm_4-3-1-2">{{away_players[7].match_shirt_number}}</div>
        <div class="right_lm_4-3-1-2">{{away_players[8].match_shirt_number}}</div>
        <div class="right_rm_4-3-1-2">{{away_players[9].match_shirt_number}}</div>
        <div class="right_cf_4-3-1-2">{{away_players[8].match_shirt_number}}</div>
      </div>
      <div class="LEFT-4-1-4-1" v-if="home_formation==='4-1-4-1'">
        <div class="gk">{{ home_players[0].match_shirt_number }}</div>
        <div class="left_lb_4-1-4-1">{{ home_players[1].match_shirt_number }}</div>
        <div class="left_cb1_4-1-4-1">{{ home_players[2].match_shirt_number }}</div>
        <div class="left_cb2_4-1-4-1">{{ home_players[3].match_shirt_number }}</div>
        <div class="left_rb_4-1-4-1">{{ home_players[4].match_shirt_number }}</div>
        <div class="left_cdm_4-1-4-1">{{ home_players[5].match_shirt_number }}</div>
        <div class="left_cm_4-1-4-1">{{ home_players[7].match_shirt_number }}</div>
        <div class="left_cm2_4-1-4-1">{{ home_players[7].match_shirt_number }}</div>
        <div class="left_lm_4-1-4-1">{{ home_players[8].match_shirt_number }}</div>
        <div class="left_rm_4-1-4-1">{{ home_players[9].match_shirt_number }}</div>
        <div class="left_cf_4-1-4-1">{{ home_players[10].match_shirt_number }}</div>
      </div>
      <div class="RIGHT-4-1-4-1" v-if="away_formation==='4-1-4-1'">
        <div class="gk2">{{away_players[0].match_shirt_number}}</div>
        <div class="right_lb_4-1-4-1">{{away_players[1].match_shirt_number}}</div>
        <div class="right_cb1_4-1-4-1">{{away_players[2].match_shirt_number}}</div>
        <div class="right_cb2_4-1-4-1">{{away_players[3].match_shirt_number}}</div>
        <div class="right_rb_4-1-4-1">{{away_players[4].match_shirt_number}}</div>
        <div class="right_cdm_4-1-4-1">{{away_players[5].match_shirt_number}}</div>
        <div class="right_cm_4-1-4-1">{{away_players[7].match_shirt_number}}</div>
        <div class="right_cm2_4-1-4-1">{{away_players[7].match_shirt_number}}</div>
        <div class="right_lm_4-1-4-1">{{away_players[8].match_shirt_number}}</div>
        <div class="right_rm_4-1-4-1">{{away_players[9].match_shirt_number}}</div>
        <div class="right_cf_4-1-4-1">{{away_players[10].match_shirt_number}}</div>
      </div>
      <div class="LEFT-4-3-2-1" v-if="home_formation==='4-3-2-1'">
        <div class="gk">{{ home_players[0].match_shirt_number }}</div>
        <div class="left_lb_4-3-2-1">{{ home_players[1].match_shirt_number }}</div>
        <div class="left_cb1_4-3-2-1">{{ home_players[2].match_shirt_number }}</div>
        <div class="left_cb2_4-3-2-1">{{ home_players[3].match_shirt_number }}</div>
        <div class="left_rb_4-3-2-1">{{ home_players[4].match_shirt_number }}</div>
        <div class="left_cdm_4-3-2-1">{{ home_players[5].match_shirt_number }}</div>
        <div class="left_cdm2_4-3-2-1">{{ home_players[6].match_shirt_number }}</div>
        <div class="left_cm_4-3-2-1">{{ home_players[7].match_shirt_number }}</div>
        <div class="left_lm_4-3-2-1">{{ home_players[8].match_shirt_number }}</div>
        <div class="left_rm_4-3-2-1">{{ home_players[9].match_shirt_number }}</div>
        <div class="left_cf_4-3-2-1">{{ home_players[10].match_shirt_number }}</div>
      </div>
      <div class="RIGHT-4-3-2-1" v-if="away_formation==='4-3-2-1'">
        <div class="gk2">{{away_players[0].match_shirt_number}}</div>
        <div class="right_lb_4-3-2-1">{{away_players[1].match_shirt_number}}</div>
        <div class="right_cb1_4-3-2-1">{{away_players[2].match_shirt_number}}</div>
        <div class="right_cb2_4-3-2-1">{{away_players[3].match_shirt_number}}</div>
        <div class="right_rb_4-3-2-1">{{away_players[4].match_shirt_number}}</div>
        <div class="right_cdm_4-3-2-1">{{away_players[5].match_shirt_number}}</div>
        <div class="right_cdm2_4-3-2-1">{{away_players[6].match_shirt_number}}</div>
        <div class="right_cm_4-3-2-1">{{away_players[7].match_shirt_number}}</div>
        <div class="right_lm_4-3-2-1">{{away_players[8].match_shirt_number}}</div>
        <div class="right_rm_4-3-2-1">{{away_players[9].match_shirt_number}}</div>
        <div class="right_cf_4-3-2-1">{{away_players[10].match_shirt_number}}</div>
      </div>
      <div class="LEFT-5-3-2" v-if="home_formation==='5-3-2'">
        <div class="gk">{{ home_players[0].match_shirt_number }}</div>
        <div class="left_lb_5-3-2">{{ home_players[1].match_shirt_number }}</div>
        <div class="left_cb1_5-3-2">{{ home_players[2].match_shirt_number }}</div>
        <div class="left_cb2_5-3-2">{{ home_players[3].match_shirt_number }}</div>
        <div class="left_rb_5-3-2">{{ home_players[4].match_shirt_number }}</div>
        <div class="left_cdm_5-3-2">{{ home_players[5].match_shirt_number }}</div>
        <div class="left_cdm2_5-3-2">{{ home_players[6].match_shirt_number }}</div>
        <div class="left_cm_5-3-2">{{ home_players[7].match_shirt_number }}</div>
        <div class="left_lm_5-3-2">{{ home_players[8].match_shirt_number }}</div>
        <div class="left_rm_5-3-2">{{ home_players[9].match_shirt_number }}</div>
        <div class="left_cf_5-3-2">{{ home_players[10].match_shirt_number }}</div>
      </div>
      <div class="RIGHT-5-3-2" v-if="away_formation==='5-3-2'">
        <div class="gk2">{{away_players[0].match_shirt_number}}</div>
        <div class="right_lb_5-3-2">{{away_players[1].match_shirt_number}}</div>
        <div class="right_cb1_5-3-2">{{away_players[2].match_shirt_number}}</div>
        <div class="right_cb2_5-3-2">{{away_players[3].match_shirt_number}}</div>
        <div class="right_rb_5-3-2">{{away_players[4].match_shirt_number}}</div>
        <div class="right_cdm_5-3-2">{{away_players[5].match_shirt_number}}</div>
        <div class="right_cdm2_5-3-2">{{away_players[6].match_shirt_number}}</div>
        <div class="right_cm_5-3-2">{{away_players[7].match_shirt_number}}</div>
        <div class="right_lm_5-3-2">{{away_players[8].match_shirt_number}}</div>
        <div class="right_rm_5-3-2">{{away_players[9].match_shirt_number}}</div>
        <div class="right_cf_5-3-2">{{away_players[10].match_shirt_number}}</div>
      </div>
      <div class="LEFT-3-4-3" v-if="home_formation==='3-4-3'">
        <div class="gk">{{ home_players[0].match_shirt_number }}</div>
        <div class="left_cb1_3-4-3">{{ home_players[2].match_shirt_number }}</div>
        <div class="left_cb2_3-4-3">{{ home_players[3].match_shirt_number }}</div>
        <div class="left_cb3_3-4-3">{{ home_players[4].match_shirt_number }}</div>
        <div class="left_cm1_3-4-3">{{ home_players[1].match_shirt_number }}</div>
        <div class="left_cm2_3-4-3">{{ home_players[5].match_shirt_number }}</div>
        <div class="left_cm3_3-4-3">{{ home_players[6].match_shirt_number }}</div>
        <div class="left_cm4_3-4-3">{{ home_players[7].match_shirt_number }}</div>
        <div class="left_lw_3-4-3">{{ home_players[8].match_shirt_number }}</div>
        <div class="left_rw_3-4-3">{{ home_players[9].match_shirt_number }}</div>
        <div class="left_cf_3-4-3">{{ home_players[10].match_shirt_number }}</div>
      </div>
      <div class="RIGHT-3-4-3" v-if="away_formation==='3-4-3'">
        <div class="gk2">{{ home_players[0].match_shirt_number }}</div>
        <div class="right_cb1_3-4-3">{{ home_players[2].match_shirt_number }}</div>
        <div class="right_cb2_3-4-3">{{ home_players[3].match_shirt_number }}</div>
        <div class="right_cb3_3-4-3">{{ home_players[4].match_shirt_number }}</div>
        <div class="right_cm1_3-4-3">{{ home_players[1].match_shirt_number }}</div>
        <div class="right_cm2_3-4-3">{{ home_players[5].match_shirt_number }}</div>
        <div class="right_cm3_3-4-3">{{ home_players[6].match_shirt_number }}</div>
        <div class="right_cm4_3-4-3">{{ home_players[7].match_shirt_number }}</div>
        <div class="right_lw_3-4-3">{{ home_players[8].match_shirt_number }}</div>
        <div class="right_rw_3-4-3">{{ home_players[9].match_shirt_number }}</div>
        <div class="right_cf_3-4-3">{{ home_players[10].match_shirt_number }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FootballPitch',
  props: {
    home_players: {
      type: Array,
    },
    away_players: {
      type: Array,
    },
    home_team_formation_label: {
      type: String,
    },
    away_team_formation_label: {
      type: String,
    },
  },
  data() {
    return {
      formations: ['4-4-2', '4-3-3', '4-2-3-1', '4-5-1', '4-3-1-2', '4-1-4-1', '4-1-4-1', '4-3-2-1', '5-3-2', '3-4-3'],
      home_formation: '4-4-2',
      away_formation: '4-4-2',
    };
  },
  beforeMount() {
    // eslint-disable-next-line no-plusplus
    let counter = 0;
    while (counter < this.formations.length) {
      if (this.formations[counter] === this.home_team_formation_label) {
        this.home_formation = this.formations[counter];
      }
      if (this.formations[counter] === this.away_team_formation_label) {
        this.away_formation = this.formations[counter];
      }
      // eslint-disable-next-line no-plusplus
      counter++;
    }
  },
};
</script>

<style scoped>
.wrapper {
  width:100%;
  margin-top:40px;
  padding:5px 10px;
  background-color:#658D5D;
  border-radius:5px;
}
.Pitch {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 0;
  margin: 10px auto;
  padding: 0 0 60% 0;
  background-color:#658D5D;
}
.Interior, .Outline,
.LeftPitch, .RightPitch,
.corner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  border: 1px solid #FFFFFF;
}
.Outline:after, .Outline:before {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  width: 0;
  height: 100%;
  margin-left: .5px;
  border: 0.5px solid #FFFFFF;
}
.Outline:before {
  top: 50%;
  width: 17%;
  height: 0;
  margin: calc(-8% - 4px) 0 0 calc(-8% - .5px);
  padding-bottom: 17%;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
}
.Pitch:before, .Pitch:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 1px;
  margin: -8% 0 0 -1px;
  display: block;
  width: 8%;
  height: 30%;
  border: 1px solid #FFFFFF;
  z-index: 2;
}
.Pitch:after {
  left: auto;
  right: 0;
}
.Interior:before, .Interior:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  margin: -16% 0 0 -1px;
  display: block;
  width: 16%;
  height: 55%;
  background-color: #658D5D;
  border: 1px solid #FFFFFF;
}
.Interior:after {
  left: auto;
  right: 0;
  margin: -16% -1px 0 0;
}
.RightPitch:before {
  left: auto;
  right: 12%;
}
.LeftPitch:after, .RightPitch:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 5%;
  width: 16%;
  height: 0;
  margin: -8% 0 0 0;
  padding-bottom: 16%;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
}
.RightPitch:after {
  left: auto;
  right: 5%;
}

.gk{
  content: "";
  position: absolute;
  top: 48%;
  left: 1.5%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.gk2{
  content: "";
  position: absolute;
  top: 48%;
  left: 93.5%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}

/*4-4-2 LEFT*/
.left_lb_4_4_2{
  content: "";
  position: absolute;
  top: 10%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb1_4_4_2{
  content: "";
  position: absolute;
  top: 35%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb2_4_4_2{
  content: "";
  position: absolute;
  top: 60%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rb_4_4_2{
  content: "";
  position: absolute;
  top: 85%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_lm_4_4_2{
  content: "";
  position: absolute;
  top: 10%;
  left: 30%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cm1_4_4_2{
  content: "";
  position: absolute;
  top: 35%;
  left: 30%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cm2_4_4_2{
  content: "";
  position: absolute;
  top: 60%;
  left: 30%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rm_4_4_2{
  content: "";
  position: absolute;
  top: 85%;
  left: 30%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_fw1_4_4_2{
  content: "";
  position: absolute;
  top: 35%;
  left: 42%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_fw2_4_4_2{
  content: "";
  position: absolute;
  top: 60%;
  left: 42%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
/*4-4-2 RIGHT*/
.right_lb_4_4_2{
  content: "";
  position: absolute;
  top: 10%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb1_4_4_2{
  content: "";
  position: absolute;
  top: 35%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb2_4_4_2 {
  content: "";
  position: absolute;
  top: 60%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rb_4_4_2{
  content: "";
  position: absolute;
  top: 85%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_lm_4_4_2{
  content: "";
  position: absolute;
  top: 10%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cm1_4_4_2{
  content: "";
  position: absolute;
  top: 35%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cm2_4_4_2{
  content: "";
  position: absolute;
  top: 60%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rm_4_4_2{
  content: "";
  position: absolute;
  top: 85%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_fw1_4_4_2{
  content: "";
  position: absolute;
  top: 35%;
  left: 55%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_fw2_4_4_2{
  content: "";
  position: absolute;
  top: 60%;
  left: 54%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}

/*4-3-3 LEFT*/
.left_cdm_4_3_3 {
  content: "";
  position: absolute;
  top: 48%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_lcm_4_3_3{
  content: "";
  position: absolute;
  top: 30%;
  left: 29%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rcm_4_3_3{
  content: "";
  position: absolute;
  top: 65%;
  left: 29%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_lw_4_3_3{
  content: "";
  position: absolute;
  top: 10%;
  left: 42%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rw_4_3_3{
  content: "";
  position: absolute;
  top: 85%;
  left: 42%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cf_4_3_3{
  content: "";
  position: absolute;
  top: 47%;
  left: 44%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
/*4-3-3 RIGHT*/
.right_cdm_4_3_3 {
  content: "";
  position: absolute;
  top: 48%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_lcm_4_3_3{
  content: "";
  position: absolute;
  top: 30%;
  left: 68%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rcm_4_3_3{
  content: "";
  position: absolute;
  top: 65%;
  left: 68%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_lw_4_3_3{
  content: "";
  position: absolute;
  top: 10%;
  left: 55%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rw_4_3_3{
  content: "";
  position: absolute;
  top: 85%;
  left: 55%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cf_4_3_3{
  content: "";
  position: absolute;
  top: 47%;
  left: 52%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}

/*4-2-3-1 LEFT*/
.left_lb_4_2_3_1{
  content: "";
  position: absolute;
  top: 10%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb1_4_2_3_1{
  content: "";
  position: absolute;
  top: 35%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb2_4_2_3_1{
  content: "";
  position: absolute;
  top: 60%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rb_4_2_3_1{
  content: "";
  position: absolute;
  top: 85%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cdm_4_2_3_1{
  content: "";
  position: absolute;
  top: 25%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em
}
.left_cdm2_4_2_3_1{
  content: "";
  position: absolute;
  top: 65%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cm_4_2_3_1{
  content: "";
  position: absolute;
  top: 47%;
  left: 30%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_lm_4_2_3_1{
  content: "";
  position: absolute;
  top: 20%;
  left: 35%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rm_4_2_3_1{
  content: "";
  position: absolute;
  top: 75%;
  left: 35%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cf_4_2_3_1{
  content: "";
  position: absolute;
  top: 47%;
  left: 42%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
/*4-2-3-1 RIGHT*/
.right_lb_4_2_3_1{
  content: "";
  position: absolute;
  top: 10%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb1_4_2_3_1{
  content: "";
  position: absolute;
  top: 35%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb2_4_2_3_1{
  content: "";
  position: absolute;
  top: 60%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rb_4_2_3_1{
  content: "";
  position: absolute;
  top: 85%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cdm_4_2_3_1{
  content: "";
  position: absolute;
  top: 25%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cdm2_4_2_3_1{
  content: "";
  position: absolute;
  top: 65%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cm_4_2_3_1{
  content: "";
  position: absolute;
  top: 47%;
  left: 65%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_lm_4_2_3_1{
  content: "";
  position: absolute;
  top: 20%;
  left: 60%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rm_4_2_3_1{
  content: "";
  position: absolute;
  top: 75%;
  left: 60%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cf_4_2_3_1{
  content: "";
  position: absolute;
  top: 47%;
  left: 54%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}

/*4-5-1 LEFT*/
.left_lb_4_5_1{
  content: "";
  position: absolute;
  top: 10%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb1_4_5_1{
  content: "";
  position: absolute;
  top: 35%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb2_4_5_1{
  content: "";
  position: absolute;
  top: 60%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rb_4_5_1{
  content: "";
  position: absolute;
  top: 85%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cdm_4_5_1{
  content: "";
  position: absolute;
  top: 45%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em
}
.left_lcm_4_5_1{
  content: "";
  position: absolute;
  top: 20%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rcm_4_5_1{
  content: "";
  position: absolute;
  top: 70%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_lw_4_5_1{
  content: "";
  position: absolute;
  top: 28%;
  left: 35%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rw_4_5_1{
  content: "";
  position: absolute;
  top: 60%;
  left: 35%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cf_4_5_1{
  content: "";
  position: absolute;
  top: 45%;
  left: 42%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
/*4-5-1 RIGHT*/
.right_lb_4_5_1{
  content: "";
  position: absolute;
  top: 10%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb1_4_5_1{
  content: "";
  position: absolute;
  top: 35%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb2_4_5_1{
  content: "";
  position: absolute;
  top: 60%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rb_4_5_1{
  content: "";
  position: absolute;
  top: 85%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cdm_4_5_1{
  content: "";
  position: absolute;
  top: 45%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em
}
.right_lcm_4_5_1{
  content: "";
  position: absolute;
  top: 20%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rcm_4_5_1{
  content: "";
  position: absolute;
  top: 70%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_lw_4_5_1{
  content: "";
  position: absolute;
  top: 28%;
  left: 60%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rw_4_5_1{
  content: "";
  position: absolute;
  top: 60%;
  left: 60%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cf_4_5_1{
  content: "";
  position: absolute;
  top: 45%;
  left: 54%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}

/*4-3-1-2 LEFT*/
.left_lb_4-3-1-2{
  content: "";
  position: absolute;
  top: 10%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb1_4-3-1-2{
  content: "";
  position: absolute;
  top: 35%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb2_4-3-1-2{
  content: "";
  position: absolute;
  top: 60%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rb_4-3-1-2{
  content: "";
  position: absolute;
  top: 85%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cdm_4-3-1-2{
  content: "";
  position: absolute;
  top: 50%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em
}
.left_cdm2_4-3-1-2{
  content: "";
  position: absolute;
  top: 65%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cm_4-3-1-2{
  content: "";
  position: absolute;
  top: 47%;
  left: 30%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_lm_4-3-1-2{
  content: "";
  position: absolute;
  top: 20%;
  left: 35%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rm_4-3-1-2{
  content: "";
  position: absolute;
  top: 75%;
  left: 35%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cf_4-3-1-2{
  content: "";
  position: absolute;
  top: 47%;
  left: 42%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
/*4-3-1-2 RIGHT*/
.right_lb_4-3-1-2{
  content: "";
  position: absolute;
  top: 10%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb1_4-3-1-2{
  content: "";
  position: absolute;
  top: 35%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb2_4-3-1-2{
  content: "";
  position: absolute;
  top: 60%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rb_4-3-1-2{
  content: "";
  position: absolute;
  top: 85%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cdm_4-3-1-2{
  content: "";
  position: absolute;
  top: 25%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cdm2_4-3-1-2{
  content: "";
  position: absolute;
  top: 70%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cm_4-3-1-2{
  content: "";
  position: absolute;
  top: 47%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_lm_4-3-1-2{
  content: "";
  position: absolute;
  top: 20%;
  left: 55%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rm_4-3-1-2{
  content: "";
  position: absolute;
  top: 75%;
  left: 55%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cf_4-3-1-2{
  content: "";
  position: absolute;
  top: 47%;
  left: 60%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}

/*4-1-4-1 LEFT*/
.left_lb_4-1-4-1{
  content: "";
  position: absolute;
  top: 10%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb1_4-1-4-1{
  content: "";
  position: absolute;
  top: 35%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb2_4-1-4-1{
  content: "";
  position: absolute;
  top: 60%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rb_4-1-4-1{
  content: "";
  position: absolute;
  top: 85%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cdm_4-1-4-1{
  content: "";
  position: absolute;
  top: 45%;
  left: 23%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em
}
.left_cm_4-1-4-1{
  content: "";
  position: absolute;
  top: 10%;
  left: 30%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cm2_4-1-4-1{
  content: "";
  position: absolute;
  top: 35%;
  left: 30%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_lm_4-1-4-1{
  content: "";
  position: absolute;
  top: 60%;
  left: 30%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rm_4-1-4-1{
  content: "";
  position: absolute;
  top: 85%;
  left: 30%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cf_4-1-4-1{
  content: "";
  position: absolute;
  top: 47%;
  left: 42%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
/*4-1-4-1 RIGHT*/
.right_lb_4-1-4-1{
  content: "";
  position: absolute;
  top: 10%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb1_4-1-4-1{
  content: "";
  position: absolute;
  top: 35%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb2_4-1-4-1{
  content: "";
  position: absolute;
  top: 60%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rb_4-1-4-1{
  content: "";
  position: absolute;
  top: 85%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cdm_4-1-4-1{
  content: "";
  position: absolute;
  top: 45%;
  left: 72%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cm_4-1-4-1{
  content: "";
  position: absolute;
  top: 10%;
  left: 65%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cm2_4-1-4-1{
  content: "";
  position: absolute;
  top: 35%;
  left: 65%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_lm_4-1-4-1{
  content: "";
  position: absolute;
  top: 60%;
  left: 65%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rm_4-1-4-1{
  content: "";
  position: absolute;
  top: 85%;
  left: 65%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cf_4-1-4-1{
  content: "";
  position: absolute;
  top: 47%;
  left: 55%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}

/*4-3-2-1 LEFT*/
.left_lb_4-3-2-1{
  content: "";
  position: absolute;
  top: 10%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb1_4-3-2-1{
  content: "";
  position: absolute;
  top: 35%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb2_4-3-2-1{
  content: "";
  position: absolute;
  top: 60%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rb_4-3-2-1{
  content: "";
  position: absolute;
  top: 85%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cdm_4-3-2-1{
  content: "";
  position: absolute;
  top: 45%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em
}
.left_cdm2_4-3-2-1{
  content: "";
  position: absolute;
  top: 65%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cm_4-3-2-1{
  content: "";
  position: absolute;
  top: 25%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_lm_4-3-2-1{
  content: "";
  position: absolute;
  top: 20%;
  left: 35%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rm_4-3-2-1{
  content: "";
  position: absolute;
  top: 75%;
  left: 35%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cf_4-3-2-1{
  content: "";
  position: absolute;
  top: 47%;
  left: 42%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
/*4-3-2-1  RIGHT*/
.right_lb_4-3-2-1{
  content: "";
  position: absolute;
  top: 10%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb1_4-3-2-1{
  content: "";
  position: absolute;
  top: 35%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb2_4-3-2-1{
  content: "";
  position: absolute;
  top: 60%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rb_4-3-2-1{
  content: "";
  position: absolute;
  top: 85%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cdm_4-3-2-1{
  content: "";
  position: absolute;
  top: 25%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cdm2_4-3-2-1{
  content: "";
  position: absolute;
  top: 70%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cm_4-3-2-1{
  content: "";
  position: absolute;
  top: 47%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_lm_4-3-2-1{
  content: "";
  position: absolute;
  top: 20%;
  left: 58%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rm_4-3-2-1{
  content: "";
  position: absolute;
  top: 75%;
  left: 58%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cf_4-3-2-1{
  content: "";
  position: absolute;
  top: 47%;
  left: 52%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}

/*5-3-2 LEFT*/
.left_lb_5-3-2{
  content: "";
  position: absolute;
  top: 10%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb1_5-3-2{
  content: "";
  position: absolute;
  top: 30%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb2_5-3-2{
  content: "";
  position: absolute;
  top: 60%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rb_5-3-2{
  content: "";
  position: absolute;
  top: 85%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cdm_5-3-2{
  content: "";
  position: absolute;
  top: 45%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cdm2_5-3-2{
  content: "";
  position: absolute;
  top: 25%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cm_5-3-2{
  content: "";
  position: absolute;
  top: 45%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_lm_5-3-2{
  content: "";
  position: absolute;
  top: 65%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rm_5-3-2{
  content: "";
  position: absolute;
  top: 55%;
  left: 40%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cf_5-3-2{
  content: "";
  position: absolute;
  top: 30%;
  left: 40%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
/*5-3-2 RIGHT*/
.right_lb_5-3-2{
  content: "";
  position: absolute;
  top: 10%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb1_5-3-2{
  content: "";
  position: absolute;
  top: 30%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb2_5-3-2{
  content: "";
  position: absolute;
  top: 60%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rb_5-3-2{
  content: "";
  position: absolute;
  top: 85%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cdm_5-3-2{
  content: "";
  position: absolute;
  top: 45%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cdm2_5-3-2{
  content: "";
  position: absolute;
  top: 25%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cm_5-3-2{
  content: "";
  position: absolute;
  top: 45%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_lm_5-3-2{
  content: "";
  position: absolute;
  top: 65%;
  left: 70%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rm_5-3-2{
  content: "";
  position: absolute;
  top: 55%;
  left: 58%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cf_5-3-2{
  content: "";
  position: absolute;
  top: 30%;
  left: 58%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}

/*3-4-3 LEFT*/
.left_cb1_3-4-3{
  content: "";
  position: absolute;
  top: 48%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb2_3-4-3{
  content: "";
  position: absolute;
  top: 20%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cb3_3-4-3{
  content: "";
  position: absolute;
  top: 72%;
  left: 14%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cm1_3-4-3{
  content: "";
  position: absolute;
  top: 33%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cm2_3-4-3{
  content: "";
  position: absolute;
  top: 12%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cm3_3-4-3{
  content: "";
  position: absolute;
  top: 55%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cm4_3-4-3{
  content: "";
  position: absolute;
  top: 77%;
  left: 25%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_lw_3-4-3{
  content: "";
  position: absolute;
  top: 20%;
  left: 38%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_rw_3-4-3{
  content: "";
  position: absolute;
  top: 70%;
  left: 38%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.left_cf_3-4-3{
  content: "";
  position: absolute;
  top: 45%;
  left: 38%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
/*3-4-3 RIGHT*/
.right_cb1_3-4-3{
  content: "";
  position: absolute;
  top: 48%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb2_3-4-3{
  content: "";
  position: absolute;
  top: 20%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cb3_3-4-3{
  content: "";
  position: absolute;
  top: 72%;
  left: 82%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cm1_3-4-3{
  content: "";
  position: absolute;
  top: 33%;
  left: 71%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cm2_3-4-3{
  content: "";
  position: absolute;
  top: 12%;
  left: 71%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cm3_3-4-3{
  content: "";
  position: absolute;
  top: 55%;
  left: 71%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cm4_3-4-3{
  content: "";
  position: absolute;
  top: 77%;
  left: 71%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_lw_3-4-3{
  content: "";
  position: absolute;
  top: 20%;
  left: 58%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_rw_3-4-3{
  content: "";
  position: absolute;
  top: 70%;
  left: 58%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
.right_cf_3-4-3{
  content: "";
  position: absolute;
  top: 45%;
  left: 58%;
  z-index: 2;
  background: #3f6c81;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;
}
</style>

<template>
  <Navbar/>
  <Loader v-if="!isLoaded"/>
  <div v-if="isLoaded">
    <FootballMatchHeader
        :away-team="match.away_team_name"
        :home-team="match.home_team_name"
        :homeScore="match.home_team_score"
        :awayScore="match.away_team_score"
        :home-team-id="match.home_team_id"
        :away-team-id="match.away_team_id"
        :home-team-badge="match.home_team_image"
        :away-team-badge="match.away_team_image"
    />
    <FootballFormation
        :home_team_formation_label="match.home_team_formation_label"
        :away_team_formation_label="match.away_team_formation_label"
        :kickoff_time="match.kickoff_time"
    />
    <div class="container">
      <div class="row justify-content-center">
        <div class="col">
          <FootballLineup id="home-team"
                          :players="match.home_team_lineup"
                          :substitutes="match.sub_home_players"
          />
        </div>
        <div class="FootballPitch">
          <FootballPitch
              :home_players="match.home_team_lineup"
              :away_players="match.away_team_lineup"
              :home_team_formation_label="match.home_team_formation_label"
              :away_team_formation_label="match.away_team_formation_label"
          />
        </div>
        <div class="col">
          <FootballLineup id="away-team"
                          :players="match.away_team_lineup"
                          :substitutes="match.sub_away_players"
          />
        </div>
      </div>
    </div>
    <FootballRefs
        :attendance="match.attendance"
        :refs="match.officials"
        :ground="match.ground"/>
    <button class="btn btn-dark"
            type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseTimeline"
            aria-expanded="false" aria-controls="collapseExample"
            id="timeLineButton">
      Open Timeline
    </button>
    <div class="collapse" id="collapseTimeline">
      <div id="football-timeline" class="container">
      <div class="row row-cols">
        <div class="col">
          <FootballTimeline
              :events="match.events"
          />
        </div>
      </div>
    </div>
    </div>
    <div id="football-stats-table" class="container">
      <div class="row row-cols">
        <div class="col">
          <FootballStatsTable v-if="isLoaded"
              :home-stats="match.home_team_stats"
              :away-stats="match.away_team_stats"
              :home-team-name="match.home_team_name"
              :away-team-name="match.away_team_name"
          />
        </div>
      </div>
    </div>
    <div class="container" id="football-threads">
      <div class="row">
        <FootballThreads v-if="isLoaded"
                         :match-id="match.match_id"
                         :threads="threads"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FootballMatchHeader from '@/components/FootballMatch/FootballMatchHeader.vue';
import FootballTimeline from '@/components/FootballMatch/FootballTimeline.vue';
import FootballLineup from '@/components/FootballMatch/FootballLineup.vue';
import FootballStatsTable from '@/components/FootballMatch/FootballStatsChart.vue';
import FootballPitch from '@/components/FootballMatch/FootballPitch.vue';
import FootballFormation from '@/components/FootballMatch/FootballFormation.vue';
import FootballThreads from '@/components/FootballMatch/FootballThreads.vue';
import FootballRefs from '@/components/FootballMatch/FootballRefs.vue';
import Loader from '@/components/Loader.vue';
import Navbar from '@/components/NavBar.vue';

export default {
  name: 'FootballMatch',
  components: {
    FootballRefs,
    FootballFormation,
    FootballPitch,
    FootballStatsTable,
    FootballTimeline,
    FootballMatchHeader,
    FootballLineup,
    FootballThreads,
    Loader,
    Navbar,
  },
  data() {
    return {
      match: {},
      isLoaded: false,
      threads: [],
    };
  },
  methods: {
    async getMatch() {
      await this.axios.get(`https://${this.$store.state.ip}/get-match-data/${this.$route.params.id}`)
        .then((response) => {
          // eslint-disable-next-line prefer-destructuring
          this.match = response.data;
          // check if match.ground key exists
          if (this.match.ground === undefined) {
            this.match.ground = 'N/A';
          }
          this.isLoaded = true;
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          this.$router.push('/:catchAll(.*)');
        });
    },
    async getThreads() {
      await this.axios.get(`https://${this.$store.state.ip}/get-match-threads/${this.$route.params.id}`)
        .then((response) => {
          // eslint-disable-next-line prefer-destructuring
          this.threads = response.data;
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          // this.$router.push('/:catchAll(.*)');
        });
    },
  },
  created() {
    this.getMatch(this.$route.params.id);
    this.getThreads(this.$route.params.id);
  },
};
</script>

<style scoped>
.col {
  /*border: 1px solid black;*/
}
.container {
  margin-top: 2em;
}
#football-timeline {
  max-width: 36em;
}
#football-stats-table {
  margin-bottom: 2em;
}
#timeLineButton {
  margin-top: 1em;
  width: 25vw;
}
#football-threads {
  margin-bottom: 2em;
}
.FootballPitch {
  min-width: 350px;
  margin-bottom: 50px;
  max-width: 550px;
}
</style>

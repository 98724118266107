<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="list-group list-group-flush">
          <Chart type="bar"
                 :data="stackedData"
                 :options="stackedOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'primevue/chart';

export default {
  name: 'FootballStatsTable',
  components: {
    Chart,
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, context) {
    return {
      stackedData: {
        labels: ['Possession', 'Shots On Target', 'Shots', 'Touches', 'Passes', 'Tackles', 'Clearances', 'Corners', 'Offsides', 'Yellow Cards', 'Red Cards', 'Fouls Conceded'],
        datasets: [{
          type: 'bar',
          label: props.homeTeamName,
          backgroundColor: '#3f6b80',
          data: props.homeStats,
        }, {
          type: 'bar',
          label: props.awayTeamName,
          backgroundColor: '#648c5c',
          data: props.awayStats,
        }],
      },
    };
  },
  props: {
    homeStats: {
      type: Object,
      required: true,
    },
    awayStats: {
      type: Object,
      required: true,
    },
    homeTeamName: {
      type: String,
      required: true,
    },
    awayTeamName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      render: false,
      stackedOptions: {
        indexAxis: 'y',
        responsive: true,
        minBarLength: 25,
        plugins: {
          legend: {
            labels: {
              color: '#ebedef',
              font: {
                size: 14,
                family: 'Uni Sans Heavy',
              },
            },
          },
          tooltip: {
            enabled: true,
            backgroundColor: '#000000',
            displayColors: false,
            callbacks: {
              label(tooltipItems) {
                if (tooltipItems.dataset.data[tooltipItems.dataIndex] === 0) {
                  return '0';
                }
                return Math.abs(tooltipItems.dataset.data[tooltipItems.dataIndex]);
              },
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: '#ebedef',
              font: {
                size: 14,
                family: 'Uni Sans Heavy',
              },
              // eslint-disable-next-line no-unused-vars
              callback(t, i) {
                return t < 0 ? Math.abs(t) : t;
              },
            },
            grid: {
              color: '#ebedef',
              font: {
                size: 14,
                family: 'Uni Sans Heavy',
              },
              // eslint-disable-next-line no-unused-vars
            },
          },
          y: {
            stacked: true,
            ticks: {
              color: '#ffffff',
              font: {
                size: 14,
                family: 'Uni Sans Heavy',
              },
            },
            grid: {
              color: '#ebedef',
            },
            font: {
              family: 'Uni Sans Heavy',
            },
          },
        },
      },
    };
  },
  onLoad() {
    this.render = true;
  },
};
</script>

<style scoped>
</style>
